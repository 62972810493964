import React from "react";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";
import { DriverMarker } from "./subcomponents/DriverMarker";
import { DriverIcon } from "./subcomponents/DriverIcon";

const southEastCoords = {
  lat: 51.37717715525353,
  lng: -0.3888276196534618,
};
export class DriverTray extends React.Component {
  constructor() {
    super();

    this.state = {
      selectedDriver: null,
      hoveredDriver: null,
      focusLat: southEastCoords.lat,
      focusLng: southEastCoords.lng,
    };
  }

  coordsForDriver = (driverId) => {
    const mergedDevices = this.props.drivers.concat(this.props.machines);
    const selectedDriver = mergedDevices.find(
      (driver) => driver.data.id === driverId
    );
    return {
      lat: selectedDriver.data.attributes.loc_latitude,
      lng: selectedDriver.data.attributes.loc_longitude,
    };
  };

  mapZoom = () => {
    if (this.state.selectedDriver) {
      return 11;
    } else {
      return 8;
    }
  };

  render() {
    return (
      <div className="lg:col-span-4 flex flex-col items-stretch">
        <div className="flex flex-col shadow-lg" style={{ height: "500px" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyCFtkL-zUziK2jgmt902NtO5WcbNSOzsso",
            }}
            defaultCenter={southEastCoords}
            defaultZoom={8}
            zoom={this.mapZoom()}
            center={{ lat: this.state.focusLat, lng: this.state.focusLng }}
          >
            {this.props.machines.map((machine, index) => (
              <DriverMarker
                machine
                selectionExists={!!this.state.selectedDriver}
                isSelected={this.state.selectedDriver === machine.data.id}
                key={`machine-pin-${index}`}
                lat={machine.data.attributes.loc_latitude}
                lng={machine.data.attributes.loc_longitude}
                colour={machine.data.attributes.avatar_colour}
                text={machine.data.attributes.name}
                onMouseEnter={() =>
                  this.setState({ hoveredDriver: machine.data.id })
                }
                onMouseLeave={() => this.setState({ hoveredDriver: null })}
              />
            ))}
            {this.props.drivers.map((driver, index) => (
              <DriverMarker
                selectionExists={!!this.state.selectedDriver}
                isSelected={this.state.selectedDriver === driver.data.id}
                key={`driver-pin-${index}`}
                lat={driver.data.attributes.loc_latitude}
                lng={driver.data.attributes.loc_longitude}
                colour={driver.data.attributes.avatar_colour}
                text={driver.data.attributes.name}
                onMouseEnter={() =>
                  this.setState({ hoveredDriver: driver.data.id })
                }
                onMouseLeave={() => this.setState({ hoveredDriver: null })}
              />
            ))}
          </GoogleMapReact>
        </div>
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 mt-2">
          {this.props.drivers.map((driver, index) => (
            <DriverIcon
              key={`driver-${index}`}
              driver={driver}
              index={index}
              selectionMode={
                (this.state.selectedDriver || this.state.hoveredDriver) !== null
              }
              isSelected={
                (this.state.selectedDriver || this.state.hoveredDriver) ==
                driver.data.id
              }
              onMouseEnter={() =>
                this.setState({
                  selectedDriver: driver.data.id,
                  focusLat: this.coordsForDriver(driver.data.id).lat,
                  focusLng: this.coordsForDriver(driver.data.id).lng,
                })
              }
              onMouseLeave={() =>
                this.setState({
                  selectedDriver: null,
                  focusLat: southEastCoords.lat,
                  focusLng: southEastCoords.lng,
                })
              }
            />
          ))}
          {this.props.machines.map((machine, index) => (
            <DriverIcon
              machine
              key={`machine-${index}`}
              driver={machine}
              index={index}
              selectionMode={
                (this.state.selectedDriver || this.state.hoveredDriver) !== null
              }
              isSelected={
                (this.state.selectedDriver || this.state.hoveredDriver) ==
                machine.data.id
              }
              onMouseEnter={() =>
                this.setState({
                  selectedDriver: machine.data.id,
                  focusLat: this.coordsForDriver(machine.data.id).lat,
                  focusLng: this.coordsForDriver(machine.data.id).lng,
                })
              }
              onMouseLeave={() =>
                this.setState({
                  selectedDriver: null,
                  focusLat: southEastCoords.lat,
                  focusLng: southEastCoords.lng,
                })
              }
            />
          ))}
        </div>
      </div>
    );
  }

  static defaultProps = {
    drivers: [],
    machines: [],
  };
}

DriverTray.propTypes = {
  drivers: PropTypes.arrayOf(PropTypes.shape),
  machines: PropTypes.arrayOf(PropTypes.shape),
};
